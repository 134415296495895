<template>
  <div>
    <main class="form-signin">
      <div class="card">
        <div class="card-body">
          <h2 class="h3 mb-3 fw-normal text-center"></h2>
          <div style="text-align: center"><img src="../assets/attend_main3.png" /></div>
          <form name="loginform" id="loginform" v-on:submit="loginsubmit">
            <div class="form-group">
              <label><i class="bi bi-telephone-fill"></i>휴대폰번호</label>
              <input
                type="text"
                class="form-control form-control-lg"
                id="tel"
                v-model="tel"
                name="tel"
                placeholder="숫자만 입력해주세요."
              />
            </div>
            <div class="form-group">
              <label><i class="bi bi-box-arrow-in-right"></i> 비밀번호</label>
              <input
                type="password"
                class="form-control form-control-lg"
                id="auth"
                v-model="auth"
                name="auth"
                autocomplete="off"
                v-on:keyup.enter="submit"
              />
            </div>
            <button
              type="submit"
              class="btn btn-primary btn-lg btn-block"
              v-if="sUser == 0"
            >
              로그인
            </button>
            <div class="btn-group" v-else>
              <select
                class="form-control"
                id="gradelvl"
                name="gradelvl"
                v-model="gradelvl"
                @change="getGradelvl()"
                style="background-color: lightcyan"
              >
                <option
                  v-for="gradelist in gradeLists"
                  :key="gradelist.grade"
                  v-bind:value="gradelist.grade"
                  :id="gradelist.grade"
                  style="background-color: white"
                >
                  {{ gradelist.gradename }}
                </option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <p class="mt-3 mb-3 text-muted text-center">
        ©2023 교육부 |
        <!-- <a href="https://open.kakao.com/o/sWIKb12d">문의</a> | -->
        <button type="button" class="btn btn-light btn-sm" @click="infopopup()">
          안내
        </button>
        |
        <!-- <button type="button" class="btn btn-light btn-sm" @click="newMember()">
          가입
        </button>
        | -->
        <button
          type="button"
          class="btn btn-light btn-sm"
          @click="openChagePasswordModal()"
        >
          비밀번호 변경
        </button>
      </p>
      <p class="mt-3 mb-3 text-muted text-center"></p>
    </main>

    <div
      class="modal fade"
      id="loginPwChangeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="loginPwChangeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="loginPwChangeModalLabel"
              style="color: blue"
            >
              <span id="plpmo"></span> 비밀번호 변경
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <form
                id="sendsmsform"
                name="sendsmsform"
                v-on:submit="submSmsForm"
              >
                <div class="form-group mb-2"></div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    id="receiver"
                    name="receiver"
                    @keyup="telnumberValid"
                    placeholder="휴대폰 번호(숫자만)"
                  />
                  <input
                    type="hidden"
                    name="sender"
                    value="01000000000"
                    id="sender"
                  />
                  <input
                    type="hidden"
                    name="msg_type"
                    value="SMS"
                    id="msg_type"
                  />
                  <input
                    type="hidden"
                    name="title"
                    value="접속을 위한 인증 번호입니다."
                    id="title"
                  />
                </div>
                <div style="text-align: right; margin-right: 20px">
                  <label></label>
                  <button
                    type="submit"
                    class="btn btn-success mb-2"
                    @click="submit"
                    :disabled="btnable"
                  >
                    문자발송
                  </button>
                </div>
              </form>
              <form
                id="changePwdform"
                name="changePwdform"
                v-on:submit="changPwd"
              >
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="password"
                    class="form-control"
                    id="ReceiveNumber"
                    name="ReceiveNumber"
                    autocomplete="off"
                    placeholder="인증번호 4자리"
                  />
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="password"
                    class="form-control"
                    id="changePwNumber"
                    name="changePwNumber"
                    autocomplete="off"
                    placeholder="변경 비밀번호"
                  />
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="password"
                    class="form-control"
                    id="confirmPwNumber"
                    name="confirmPwNumber"
                    autocomplete="off"
                    placeholder="비밀번호 확인"
                  />
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary">등록</button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 회원가입 -->
    <div
      class="modal fade"
      id="newMemberModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="newMemberModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="newMemberModalLabel"
              style="color: blue"
            >
              <span id="plpmo"></span> 회원가입
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <form
                id="newmemberform"
                name="newmemberform"
                v-on:submit="memberForm"
              >
                <div class="form-group mb-2"></div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    id="newreceiver"
                    name="newreceiver"
                    @keyup="telnumberValid"
                    placeholder="휴대폰 번호(숫자만)"
                  />
                </div>
                <!-- <div style="text-align: right; margin-right: 20px">
                  <label></label>
                  <button
                    type="submit"
                    class="btn btn-success mb-2"
                    @click="submit"
                    :disabled="btnable"
                  >
                    문자발송
                  </button>
                </div> -->
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    id="tname"
                    name="tname"
                    autocomplete="off"
                    placeholder="이름"
                  />
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    id="dept"
                    name="dept"
                    autocomplete="off"
                    placeholder="소속교회"
                  />
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    id="rank"
                    name="rank"
                    autocomplete="off"
                    placeholder="직분"
                  />
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="password"
                    class="form-control"
                    id="pwd1"
                    name="pwd1"
                    autocomplete="off"
                    placeholder="비밀번호"
                  />
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="password"
                    class="form-control"
                    id="pwd2"
                    name="pwd2"
                    autocomplete="off"
                    placeholder="비밀번호 재입력"
                  />
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary">가입</button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 안내팝업 -->
    <div
      class="modal fade"
      id="infopopupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="infopopupModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="infopopupModalLabel"
              style="color: blue"
            >
              <span id="plpmo"></span> 이용안내
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <div class="form-group mb-2"></div>
              <div class="form-group mx-sm-3 mb-2">
                <p>
                  교육부 만나 출석 앱은 동안교회(서울) 교육부서에서 허가된
                 학부모에 한해 사용이 가능합니다.
                </p>
                <p>※ 로그인이  안될 경우 새로고침 또는 앱을 종료후 다시 실행해주세요.</p>
                <p>
                  기타 문의사항은 아래 카카오톡으로 문의바랍니다. (
                  <a href="https://open.kakao.com/o/sWIKb12d"> Click → 카카오톡 문의</a>) 
                </p>

                <button v-if="deferredPrompt" @onClick="promptInstall">
                  Add to home screen
                </button>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //import axios from "axios";
  import LoginService from "../services/LoginService";
  import $ from "jquery";

  export default {
    name: "Login",
    data() {
      return {
        tel: this.$cookies.get("phone"),
        auth: "",
        logininfo: [],
        toastCount: 0,
        email: "",
        password: "1111",
        btnable: true,
        sUser: 0,
        gradelvl: 0,
        gradeLists: [
          { grade: "0", gradename: "부서를 선택해주세요." },
          { grade: "4", gradename: "청소년1부플러스" },
          { grade: "2", gradename: "청소년2부" },
          { grade: "3", gradename: "청소년3부" },
        ],

        //{ grade: "1", gradename: "청소년1부" },
        //{ grade: "4", gradename: "청소년1부 플러스" },
        deferredPrompt : false,
      };
    },
    computed: {},
    methods: {
      //수퍼 유저가 접속합니다.
      getGradelvl() {
        var object = {};
        var form = document.getElementById("loginform");
        var fform = new FormData(form);
        fform.forEach(function (value, key) {
          object[key] = value;
        });

        const data = {
          tel: object.tel,
          passwd: object.auth,
          grade: this.gradelvl,
        };

        localStorage.setItem("phone", JSON.stringify(object.tel));

        LoginService.sendTel(data).then(
          (response) => {
            if (response.status == 200 && response.data.length > 0) {
              //if (response.status == 200 && response.data[0].id > 0) {
              //this.cards = result.data[0];
              response.data[0].classid = this.gradelvl;
              localStorage.setItem("phone", response.data[0].phone);
              localStorage.setItem("classid", this.gradelvl);
              localStorage.setItem("studentid", response.data[0].studentid);
             
              localStorage.setItem("phone", response.data[0].phone);
                this.sUser = response.data[0].superuser;
                this.$store.dispatch('login', response.data[0]); // Vuex를 사용하여 로그인 상태 업데이트
                this.$store.commit("loginToken", response.data[0]);
                this.$cookies.set("phone", response.data[0].phone, "30d");                
                this.$router.push({ path: "/personreport" });

            } else {
              this.$toast.info("로그인 정보를 다시 확인해주세요", {
                type: "warning",
                position: "top-right",
              });
              return;
            }
          },
          function (err) {
            this.$toast.error(
              "연결에 문제가 있습니다. 관리자에게 문의바랍니다."
            );
            console.log(err);
          }
        );
      },
      //전화번호 11자리 체크하여 인증 확인용
      telnumberValid() {
        var receivertel = document.getElementById("receiver").value;
        const replacer = new RegExp("-", "g");
        var replacetel = receivertel.replace(replacer, "");

        if (replacetel.length == 11) {
          //11자리가 채워지면

          const data = {
            tel: replacetel,
          };

          LoginService.findTelNumber(data)
            .then((response) => {
              if (response.data[0].id > 0) {
                this.btnable = false;
                this.$toast.show("등록된 번호로 확인되었습니다.", {
                  type: "warning",
                  position: "top-right",
                });
              }
            })
            .catch((e) => {
              console.log(e);
              this.btnable = true;
              this.$toast.show(
                "등록된 번호가 아닙니다. 관리자에게 문의하세요.",
                {
                  type: "error",
                  position: "top-right",
                }
              );
            });
        } else {
          return;
        }
      },
      loginsubmit(e) {
        e.preventDefault();

        var object = {};
        var form = document.getElementById("loginform");
        var fform = new FormData(form);
        fform.forEach(function (value, key) {
          object[key] = value;
        });

        if (object.tel.trim().length < 11) {
          this.$toast.show("휴대폰 번호를 입력해주세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        if (object.auth.trim().length < 4) {
          this.$toast.show("비밀번호를 입력해주세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        //this.login(object);
        const data = {
          tel: object.tel,
          passwd: object.auth,
          grade: "0",
          fcmtoken: localStorage.getItem("FCMToken"),
        };

        localStorage.setItem("phone", JSON.stringify(object.tel));

        LoginService.sendTel(data).then(
          (response) => {
            console.log("response", JSON.stringify(response.data[0].phone));
            if (response.status == 200 && JSON.stringify(response.data[0].phone) != undefined ) {
              //if (response.status == 200 && response.data[0].id > 0) {

              //this.cards = result.data[0];
              if (response.data[0].superuser == "0") {
                localStorage.setItem("phone", response.data[0].phone);
                localStorage.setItem("classid", response.data[0].classid);
                this.$store.dispatch('login', response.data[0]); // Vuex를 사용하여 로그인 상태 업데이트
                this.$store.commit("loginToken", response.data[0]);
                this.$cookies.set("phone", response.data[0].phone, "30d");                
                this.$router.push({ path: "/personreport" });
              } else {
                this.$toast.info("수퍼유저로 로그인됩니다.", {
                  type: "warning",
                  position: "top-right",
                });
                this.sUser = response.data[0].superuser;
              }
            } else {
              this.$toast.info("로그인 정보를 다시 확인해주세요", {
                type: "warning",
                position: "top-right",
              });
              return;
            }
          },
          function (err) {
            this.$toast.error(
              "연결에 문제가 있습니다. 관리자에게 문의바랍니다."
            );
            console.log(err);
          }
        );
      },
      //비밀번호 변경
      changPwd(e) {
        e.preventDefault();

        var telno = document.getElementById("receiver").value;

        var object = {};
        var xform = document.getElementById("changePwdform");
        var tform = new FormData(xform);
        tform.forEach(function (value, key) {
          object[key] = value;
        });

        object["tel"] = telno.trim();

        var jsondata = JSON.stringify(object);

        const sReceiveNumber = JSON.parse(jsondata).ReceiveNumber.trim();
        const schangePwNumber = JSON.parse(jsondata).changePwNumber.trim();
        const sconfirmPwNumber = JSON.parse(jsondata).confirmPwNumber.trim();

        if (telno.trim().length < 11) {
          this.$toast.show("휴대폰 번호를 정확하게 입력하세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        if (sReceiveNumber.length < 4) {
          this.$toast.show("인증번호를 정확하게 입력하세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        if (schangePwNumber.length < 4) {
          this.$toast.show("비밀번호를 정확하게 입력하세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        if (schangePwNumber != sconfirmPwNumber) {
          this.$toast.show("확인 비밀번호가 일치하지 않습니다.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        LoginService.changepasswd(jsondata)
          .then((response) => {
            //this.statusLists = response.data;
            if (response.data == true) {
              this.$toast.show("정상적으로 변경되었습니다.", {
                type: "warning",
                position: "top-right",
              });
              $("#loginPwChangeModal").modal("hide");
            } else {
              this.$toast.show("인증번호를 다시 확인바랍니다.", {
                type: "error",
                position: "top-right",
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.$toast.show(
              "사전 등록된 번호인지 또는 인증번호 확인이 필요합니다.",
              {
                type: "error",
                position: "top-right",
              }
            );
          });
      },
      //회원 가입
      memberForm(e) {
        e.preventDefault();

        var object = {};
        var nform = document.getElementById("newmemberform");
        var vform = new FormData(nform);
        vform.forEach(function (value, key) {
          object[key] = value;
          //console.log(key, value);
        });

        if (object["pwd1"] !== object["pwd2"]) {
          this.$toast.show("비밀번호가 같지 않습니다.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        const data = {
          receiver: object.newreceiver,
          tname: object.tname,
          dept: object.dept,
          rank: object.rank,
          pwd1: object.pwd1,
        };

        LoginService.memberRegister(data)
          .then((response) => {
            //this.statusLists = response.data;

            if (response.data[0] == true) {
              this.$toast.show("가입을 환영합니다.", {
                type: "warning",
                position: "top-right",
              });
              $("#newMemberModal").modal("hide");
            } else {
              this.$toast.show("정보를 다시 확인바랍니다.", {
                type: "error",
                position: "top-right",
              });
              return;
            }
          })
          .catch((e) => {
            console.log(e);
            this.$toast.show("관리자에게 문의바랍니다.", {
              type: "error",
              position: "top-right",
            });
            return;
          });
      },
      //인증 요청 문자 발송
      submSmsForm(e) {
        e.preventDefault();
        var rand = function between(min, max) {
          return Math.floor(Math.random() * (max - min) + min);
        };

        var pass = rand(1111, 9999);

        var object = {};
        var fform = document.getElementById("sendsmsform");
        var form = new FormData(fform);
        form.forEach(function (value, key) {
          object[key] = value;
        });

        object["msg"] = "접속을 위한 인증 번호는 [" + pass + "] 입니다.";
        object["keypass"] = pass;
        var jsondata = JSON.stringify(object);
        const pNum = JSON.parse(jsondata).receiver.trim().replaceAll("-", "");
        if (pNum.length != 11) {
          this.$toast.show("휴대폰번호를 정확하게 입력하세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }
        this.phone = pNum;
        LoginService.sendSms(jsondata)
          .then((response) => {
            //this.statusLists = response.data;
            console.log(response.status);
          })
          .catch((e) => {
            console.log(e);
            this.$toast.show(
              "서버에서 응답이 없습니다. \n\n 관리자에게 문의해주세요.",
              {
                type: "error",
                position: "top-right",
              }
            );
          });
      },
      sendTelNumber() {
        var phone = $("#receiver").val();
        var data = {
          sender: "01000000000",
          receiver: phone,
          msg: "",
          msg_type: "SMS",
          title: "접속을 위한 인증 번호입니다.",
          destination: "",
          rdate: "",
          rtime: "",
          image: "",
        };

        LoginService.sendSms(data)
          .then((response) => {
            console.log(response.status);
            //this.statusLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      openChagePasswordModal() {
        document.getElementById("receiver").value = "";
        document.getElementById("ReceiveNumber").value = "";
        document.getElementById("changePwNumber").value = "";
        document.getElementById("confirmPwNumber").value = "";

        $("#loginPwChangeModal").modal("show");
      },
      newMember() {
        document.newmemberform.reset();
        $("#newMemberModal").modal("show");
      },
      infopopup() {
        $("#infopopupModal").modal("show");
      },
    },
    created() {
      this.$store.commit("logout");
    },
  };
</script>

<style lang="css"></style>
